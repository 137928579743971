.el-table[data-v-1996991c] .cell {
  color: #212529;
}
.none-border p[data-v-1996991c] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #b9b9b9;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border p[data-v-1996991c]:first-child {
  border-top: none;
}
.top_btns[data-v-1996991c] {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styleForm .el-select[data-v-1996991c] {
  margin-left: 0px;
}
.bottom_button[data-v-1996991c] {
  display: inline-block;
  margin-top: 20px;
}